import { createUseStyles } from 'react-jss'
import themeConfigStyleService from '../../../services/theme-config-style-service'

const useStyles = createUseStyles((theme) => ({
  '@global': {
    ...themeConfigStyleService.getStyles({
      theme,
      isRoot: true,
      overrides: [
        {
          key: [
            'colors.pages.account.favourites.backgroundColor',
            'colors.pages.products.backgroundColor',
          ],
          styleProperty: '--ui-body-background-color',
        },
      ],
    }),
  },
  containerFluid: {
    padding: [[30, 0]],
    width: '100%',
    textAlign: 'left',
    overflow: 'hidden',
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      padding: [[60, 0]],
      width: '100%',
      textAlign: 'left',
    },
  },
  container: {
    padding: '0 2rem',
  },
  list: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    flexWrap: 'wrap',
    gap: 'var(--pages-account-favourites-list-gap)',
    padding: '1.6rem 0',
  },
  productCard: {
    flexBasis: 'var(--pages-account-favourites-item-width)',
    ...themeConfigStyleService.getStyles({
      theme,
      overrides: [
        {
          key: 'config.pages.account.favourites.item.thumbnail.position',
          styleProperty: '--ui-product-card-flex-direction',
        },
        {
          key: 'config.pages.account.favourites.item.thumbnail.position',
          styleProperty: '--ui-product-card-thumbnail-width',
        },
        {
          key: 'config.pages.account.favourites.item.thumbnail.position',
          styleProperty: '--ui-product-card-thumbnail-max-width',
        },
        {
          key: 'config.pages.account.favourites.item.thumbnail.position',
          styleProperty: '--ui-product-card-content-padding',
        },
        {
          key: 'config.pages.account.favourites.item.thumbnail.position',
          styleProperty: '--ui-product-card-content-text-align',
        },
        {
          key: 'config.pages.account.favourites.item.thumbnail.position',
          styleProperty: '--ui-product-card-price-list-justify-content',
        },
        {
          key: 'config.pages.account.favourites.item.padding',
          styleProperty: '--ui-product-card-padding',
        },
        {
          key: 'colors.pages.account.favourites.item.backgroundColor',
          styleProperty: '--ui-product-card-background-color',
        },
        {
          key: 'config.pages.account.favourites.item.roundedCorners',
          styleProperty: '--ui-product-card-border-radius',
        },
        {
          key: 'config.pages.account.favourites.item.thumbnail.roundedCorners',
          styleProperty: '--ui-product-card-image-border-radius',
        },
        {
          key: 'config.pages.account.favourites.item.price.position',
          styleProperty: '--ui-product-card-price-container-justify-content',
        },
        {
          key: 'config.pages.account.favourites.item.price.fontSize',
          styleProperty: '--ui-product-card-price-font-size',
        },
        {
          key: 'config.pages.account.favourites.item.enableDetails',
          styleProperty: '--ui-product-card-content-details-display',
        },
      ],
    }),
  },
}))

export default useStyles
